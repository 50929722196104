import React, { useState } from "react";
import classnames from "classnames";
import IconButton from "../../../1-atoms/Buttons/IconOnly";
import { See } from "../../../1-atoms/Icons/24 ";

interface PasswordInputProps {
  predefinedPassword?: string;
  label: string;
  name: string;
  error?: string;
  invalid?: boolean;
  id?: string;
  placeholder?: string;
}

function PasswordInput({
  predefinedPassword = "",
  label,
  invalid,
  name,
  className,
  error,
  placeholder,
  id = "passwordInput",
  ...props
}: PasswordInputProps & React.HTMLAttributes<HTMLInputElement>) {
  const [password, setPassword] = useState(predefinedPassword);
  const [fieldType, setFieldType] = useState<"password" | "text">("password");
  const invalidCondition = [invalid, error].some(Boolean);

  const toggleFieldType = () =>
    setFieldType(fieldType === "text" ? "password" : "text");
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  return (
    <div className={classnames(className)} {...props}>
      <div>
        <label htmlFor={id} data-testid={"password-label"}>
          {label}
        </label>
        <div className={"relative"}>
          <input
            className={classnames(
              { invalid: invalidCondition },
              "typography-xs-medium py-4 px-2 pr-9",
              "outline-none focus:border-2 focus:border-black050"
            )}
            placeholder={placeholder}
            type={fieldType}
            value={password}
            onChange={handleChange}
            name={name}
            id={id}
          />
          <IconButton
            data-testid={"reveal-password"}
            className={"absolute right-2 top-1/2 -translate-y-1/2"}
            onClick={toggleFieldType}
            label={"reveal password"}
          >
            <See className={"text-gray350"} />
          </IconButton>
        </div>
      </div>

      {error && (
        <div>
          <span role="status" aria-live="polite" className="visually-hidden">
            You have an error: {error}
          </span>
          <p
            data-testid={`${name}-error`}
            aria-hidden="true"
            className="typography-3xs-semibold 1 text-red100"
          >
            {error}
          </p>
        </div>
      )}
    </div>
  );
}

export default PasswordInput;
